import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import * as Font from 'expo-font';
import Scheduler from './src/Scheduler';
import ScheduleReducer from './src/store/reducers';
import { Provider } from 'react-redux';
import store from './src/store/store';

const FontBlack = require('./assets/fonts/Muli-Black.ttf');
const FontBlackItalic = require('./assets/fonts/Muli-BlackItalic.ttf');
const FontBold = require('./assets/fonts/Muli-Bold.ttf');
const FontBoldItalic = require('./assets/fonts/Muli-BoldItalic.ttf');
const FontExtraBold = require('./assets/fonts/Muli-ExtraBold.ttf');
const FontExtraBoldItalic = require(
    './assets/fonts/Muli-ExtraBoldItalic.ttf',
);
const FontExtraLight = require('./assets/fonts/Muli-ExtraLight.ttf');
const FontExtraLightItalic = require(
    './assets/fonts/Muli-ExtraLightItalic.ttf',
);

const FontItalic = require('./assets/fonts/Muli-Italic.ttf');
const FontLight = require('./assets/fonts/Muli-Light.ttf');
const FontLightItalic = require('./assets/fonts/Muli-LightItalic.ttf');
const Muli = require('./assets/fonts/Muli-Regular.ttf');
const FontSemiBold = require('./assets/fonts/Muli-SemiBold.ttf');
const FontSemiBoldItalic = require(
    './assets/fonts/Muli-SemiBoldItalic.ttf',
);

class App extends React.Component {
  async componentDidMount() {
    try {
      // Fonts are named after their font weights. Use the font weight reported by Invision to
      // select a font. Do not use the 'fontWeight' or 'fontStyle' RN style attributes.

      // See https://github.com/expo/expo/issues/106 for more information
      // See https://projects.invisionapp.com/d/main/default/#/console/14989627/331459178/inspect for further examples
      await Font.loadAsync({

        Muli,
        MuliItalic: FontItalic,
        MuliBold: FontBold,
        MuliBoldItalic: FontBoldItalic,

        Muli200: FontExtraLight,
        Muli200Italic: FontExtraLightItalic,

        Muli300: FontLight,
        Muli300Italic: FontLightItalic,

        Muli600: FontSemiBold,
        Muli600Italic: FontSemiBoldItalic,

        Muli800: FontExtraBold,
        Muli800Italic: FontExtraBoldItalic,

        Muli900: FontBlack,
        Muli900Italic: FontBlackItalic,

      });
      this.setState({ fontLoaded: true });
    } catch (error) {
      console.log('error in loading font', error);
    }
  }

  render() {
    return (
        <Provider store={store}>
          <Scheduler/>
        </Provider>
    );
  }
}
export default App;
