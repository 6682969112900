import React from 'react';
import { View, Text } from 'react-native';
import moment from 'moment-timezone';
import { now } from 'moment';
import styles from '../rowStyle';

const localTimezone = moment.tz.guess();
moment.tz.setDefault(localTimezone);

const HealthCol = ({
 health, timestamp, endDate, done 
}) => {
  let healthCol = null;
  const doneDays = Math.ceil(moment(timestamp).diff(moment(endDate), 'days'));
  if (health) {
    const { summary, dayDelta } = health;
    switch (summary) {
      case 'red':
        healthCol = (
          <View>
            <Text
              style={[
                styles.colorRed,
                styles.cellHealthText,
                styles.cellHealthDelta,
              ]}
            >
              +
{dayDelta}
            </Text>
            <Text style={[styles.colorRed, styles.cellHealthText]}>days</Text>
          </View>
        );
        break;
      case 'orange':
        healthCol = (
          <View>
            <Text
              style={[
                styles.colorGreen,
                styles.cellHealthText,
                styles.cellHealthDelta,
              ]}
            >
              0
            </Text>
            <Text style={[styles.colorGreen, styles.cellHealthText]}>days</Text>
          </View>
        );
        break;
      case 'green':
        if (moment(endDate) < moment() || done) {
          healthCol = (
            <View>
              <Text
                style={[
                  styles.colorGray,
                  styles.cellHealthText,
                  styles.cellHealthDelta,
                ]}
              >
                {doneDays > 0 ? `+${doneDays}` : doneDays}
              </Text>
              <Text style={[styles.colorGray, styles.cellHealthText]}>
                days
              </Text>
            </View>
          );
        } else {
          healthCol = (
            <View>
              <Text
                style={[
                  styles.colorGreen,
                  styles.cellHealthText,
                  styles.cellHealthDelta,
                ]}
              >
                {dayDelta}
              </Text>
              <Text style={[styles.colorGreen, styles.cellHealthText]}>
                days
              </Text>
            </View>
          );
        }
        break;
      default:
        return;
    }
  } else {
    healthCol = null;
  }
  // eslint-disable-next-line consistent-return
  return <View style={[styles.cell, styles.cellHealth]}>{healthCol}</View>;
};

export default HealthCol;
