import React from 'react';
import { View, Text } from 'react-native';
import styles from '../rowStyle';

const Header = () => (
  <View style={styles.header}>
    <View style={[styles.headerCell, styles.headerLine]}>
      <Text ellipsizeMode numberOfLines={1} style={styles.headerText}>
        Line
      </Text>
    </View>
    <View style={[styles.headerCell, styles.headerName]}>
      <Text ellipsizeMode numberOfLines={1} style={styles.headerText}>
        Task Name
      </Text>
    </View>
    <View style={[styles.headerCell, styles.headerHealth]}>
      <Text ellipsizeMode numberOfLines={1} style={styles.headerText}>
        Health
      </Text>
    </View>
    <View style={[styles.headerCell, styles.headerProgress]}>
      <Text ellipsizeMode numberOfLines={1} style={styles.headerText}>
        Progress
      </Text>
    </View>
    <View style={[styles.headerCell, styles.headerOwner]}>
      <Text ellipsizeMode numberOfLines={1} style={styles.headerText}>
        Owner
      </Text>
    </View>
    <View style={[styles.headerCell, styles.headerUpdate]}>
      <Text ellipsizeMode numberOfLines={1} style={styles.headerText}>
        Last Update
      </Text>
    </View>
    <View style={[styles.headerCell, styles.headerDuration]}>
      <Text ellipsizeMode numberOfLines={1} style={styles.headerText}>
        Duration
      </Text>
    </View>
  </View>
);

export default Header;
