// @flow

import * as React from 'react';
import {
  StyleSheet, View, FlatList, Dimensions,
} from 'react-native';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import LevelWrapper from './construction-program-table/LevelWrapper';
import Header from './construction-program-table/components/Header';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  table: {
    height: Dimensions.get('window').height - 268,
  },
});

type StateProps = {
  query: string
}

type Props = {
  data: ConstructionProgramView
} & StateProps;

type State = {};

class ConstructionProgramTable extends React.Component<Props, State> {
  listToTree = (list) => {
    const map = {};
    let node;
    const tree = [];
    let i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].rowId] = list[i];
      map[list[i].rowId].children = [];
    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId) {
        map[node.parentId].children.push(node);
      } else {
        tree.push(node);
      }
    }
    return tree;
  }

  containsQuery = (nodes, query) => {
    let tag = false;
    for (let i = 0; i < nodes.length; i += 1) {
      const node = nodes[i];
      const { name, children, line } = node;
      const searchTarget = `${name.toLowerCase()} ${line}`;
      if (searchTarget.includes(query) || this.containsQuery(children, query)) {
        tag = true;
        break;
      }
    }
    return tag;
  };

  cutTree = (nodes, query) => (
    cloneDeep(nodes).reduce((acc, node) => {
      const { name, children, line } = node;
      const searchTarget = `${name.toLowerCase()} ${line}`;
      if (searchTarget.includes(query)) {
        acc.push(node);
      }

      if (!searchTarget.includes(query) && this.containsQuery(children, query)) {
        node.children = this.cutTree(children, query);
        acc.push(node);
      }
      return acc;
    }, [])
  );

  reorder = (tree) => {
    const order = ['Lower Ground', 'Ground', 'Lower Mezzanine', 'Upper Mezzanine', 'East Tower', 'West Tower']; // sort order by LG G LM UM, id may change so sort by name
    return tree.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
  }

  render() {
    const { data, query } = this.props;
    const { rows } = data;
    const result = this.reorder(this.listToTree(rows));
    const filteredResults = this.cutTree(result, query);
    return (
      <View style={styles.container}>
        <Header />
        <FlatList
          style={styles.table}
          data={filteredResults}
          renderItem={({ item }) => <LevelWrapper item={item} key={item.rowId.toString() + item.name.toString()} query={query} />}
          keyExtractor={item => item.rowId.toString() + item.name.toString()}
        />
      </View>
    );
  }
}

const mapStateToProps = state => ({
  query: state.schedule.query,
});

export default connect(mapStateToProps)(ConstructionProgramTable);
