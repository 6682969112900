/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import {
  View, Text, Image,
} from 'react-native';
import styles from './style';
import { CHART_URL } from '../../store/actions';

const WallChartBtn = () => {
  const [hoverChart, setHoverChart] = useState(false);
  return (
    <a target="_blank" rel="noopener noreferrer" href={CHART_URL}>
      <View
        style={hoverChart ? [styles.wallchartSection, { cursor: 'pointer' }] : styles.wallchartSection}
        onMouseEnter={() => setHoverChart(true)}
        onMouseLeave={() => setHoverChart(false)}
      >
        <View style={hoverChart ? [styles.chartIconWrap, styles.importShadow] : styles.chartIconWrap}>
          <Image
            style={styles.wallchartIcon}
            source={hoverChart ? require('../../../assets/wallchart.png') : require('../../../assets/wallchart_grey.png')}
          />
        </View>
        <Text style={styles.wallchartText}>Wallchart</Text>
      </View>
    </a>
  );
};

export default WallChartBtn;
