import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1140,
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  cell: {
    height: 48,
    borderWidth: 1,
    borderColor: '#FFFFFF',
    borderStyle: 'solid',
    backgroundColor: '#F7F7F7',
    padding: 14,
  },
  header: {
    position: 'fixed',
    top: 220,
    width: '100%',
    height: 48,
    zIndex: 1000,
    flexDirection: 'row',
    shadowOffset: { width: 0, height: -2 },
    shadowColor: '#424242',
    shadowOpacity: 0.24,
    shadowRadius: 17,
    backgroundColor: 'white',
  },
  headerCell: {
    padding: 14,
    paddingBottom: 5,
    alignSelf: 'flex-end',
  },
  headerText: {
    fontFamily: 'Muli',
    fontSize: 13,
  },
  headerLine: {
    flex: 48,
  },
  headerName: {
    flex: 539,
  },
  headerHealth: {
    flex: 54,
  },
  headerProgress: {
    flex: 320,
  },
  headerOwner: {
    flex: 194,
  },
  headerUpdate: {
    flex: 114,
  },
  headerDuration: {
    flex: 170,
  },
  cellLine: {
    flex: 54,
  },
  cellName: {
    flex: 539,
    flexDirection: 'row',
  },
  cellHealth: {
    flex: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellProgress: {
    flex: 320,
    justifyContent: 'center',
  },
  cellOwner: {
    flex: 194,
    flexDirection: 'row',
  },
  cellUpdate: {
    flex: 114,
    alignItems: 'center',
  },
  cellDuration: {
    flex: 170,
    justifyContent: 'center',
    cursor: 'pointer',
  },
  cellDurationGrey: {
    backgroundColor: '#E0E0E0',
  },
  cellNameTriangleWrap: {
    alignSelf: 'center',
    width: 30,
  },
  cellNameTriangle: {
    width: 0,
    height: 0,
    borderLeftWidth: 6,
    borderStyle: 'solid',
    borderLeftColor: 'transparent',
    borderRightWidth: 6,
    borderRightColor: 'transparent',
    borderTopWidth: 8,
    borderTopColor: '#A8A8A8',
  },
  cellOwnerDropDown: {
    width: 34,
    backgroundColor: '#F0F0F0',
    margin: -14,
    alignItems: 'center',
  },
  cellOwnerName: {
    fontFamily: 'Muli',
    color: '#424242',
    fontSize: 17,
    marginLeft: 25,
    lineHeight: 18,
  },
  cellOwnerTriangleWrap: {
    height: '100%',
    justifyContent: 'center',
  },
  cellOwnerTriangle: {
    borderLeftWidth: 6,
    borderStyle: 'solid',
    borderLeftColor: 'transparent',
    borderRightWidth: 6,
    borderRightColor: 'transparent',
    borderTopWidth: 10,
    borderTopColor: '#D8D8D8',
  },
  cellNameTriangleRight: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderLeftWidth: 8,
    borderLeftColor: '#A8A8A8',
    borderTopWidth: 6,
    borderTopColor: 'transparent',
    borderBottomWidth: 6,
    borderBottomColor: 'transparent',
  },
  cellLineText: {
    color: '#A8A8A8',
    alignSelf: 'center',
    fontSize: 17,
    fontFamily: 'Muli',
    lineHeight: 18,
  },
  cellNameLevel: {
    color: '#424242',
    fontFamily: 'Muli900',
    fontSize: 17,
    lineHeight: 18,
    letterSpacing: 1.2,
  },
  cellNameTask: {
    color: '#A8A8A8',
    fontFamily: 'Muli',
    fontSize: 17,
    lineHeight: 18,
  },
  cellNull: {
    fontFamily: 'Muli',
    color: '#A8A8A8',
    fontSize: 17,
  },
  healthTextWrap: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellHealthText: {
    fontSize: 11,
    fontFamily: 'Muli900',
  },
  cellHealthDelta: {
    fontSize: 16,
    alignSelf: 'center',
  },
  colorRed: {
    color: '#FF6358',
  },
  colorOrange: {
    color: '#FFCE00',
  },
  colorGreen: {
    color: '#4AD238',
  },
  colorGray: {
    color: '#A8A8A8',
  },
  cellUpdateText: {
    color: '#A8A8A8',
    fontFamily: 'Muli',
    lineHeight: 17,
  },
  cellDurationWrap: {
    backgroundColor: '#C4C4C4',
    borderRadius: 5,
    alignItems: 'center',
    height: 32,
    justifyContent: 'center',
  },
  cellDurationText: {
    color: '#6F6F6F',
    fontFamily: 'Muli900',
  },
  cellDurationTextGrey: {
    fontFamily: 'Muli',
    fontSize: 15,
  },
  progressBackground: {
    backgroundColor: '#E0E0E0',
    borderRadius: 5,
    marginLeft: -10,
    marginRight: -10,
    height: 30,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  progressBars: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  progressPercent: {
    color: 'white',
    fontFamily: 'Muli900',
    fontSize: 11,
    paddingLeft: 9,
    position: 'absolute',
    zIndex: 1,
  },
  progressPercentWrap: {
    justifyContent: 'space-between',
    flex: 1,
    flexDirection: 'row',
  },
  progressNotStartedText: {
    color: '#A8A8A8',
    fontSize: 17,
    fontFamily: 'Muli900',
    alignSelf: 'center',
    lineHeight: 19,
  },
  progressGreen: {
    backgroundColor: '#4AD238',
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
  },
  progressOrange: {
    backgroundColor: '#FFCE00',
  },
  progressRed: {
    backgroundColor: '#FF6358',
  },
  progressUnused: {
    backgroundColor: 'transparent',
  },
  indicator: {
    backgroundColor: '#FF1000',
    width: 2,
    height: 48,
    left: 0,
    zIndex: 1,
    position: 'absolute',
    top: '-9px',
  },
  dateCellRequestChange: {
    backgroundColor: '#4986FF',
  },
  dateCellRequestChangeText: {
    color: 'white',
    fontSize: 13,
  },
});

export default styles;
