import 'url-search-params-polyfill';

export default {
  getAuthFromWindow() {
    const search = new URLSearchParams(window.location.search);
    const auth = search.get('a');
    if(!auth) return null;

    try {
      return JSON.parse(atob(auth));
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}
