import React from 'react';
import { View, Text, Animated } from 'react-native';
import styles from '../rowStyle';

class ProgressBar extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      widthAnim: new Animated.Value(1),
      fadeAnim: new Animated.Value(1),
    };
  }

  componentDidMount() {
    const { widthAnim, fadeAnim } = this.state;

    Animated.sequence([
      Animated.timing(
        widthAnim,
        {
          toValue: 1,
          duration: 500,
          delay: 200,
        },
      ),
      Animated.timing(
        fadeAnim,
        {
          toValue: 1,
          duration: 300,
          delay: 0,
        },
      ),
    ]).start();
  }

  render() {
    const { progress, delta } = this.props;
    const { widthAnim, fadeAnim } = this.state;

    let progressCol = null;
    if (progress) {
      const {
        percentGreen, percentOrange, percentRed, percentTimeConsumed,
      } = progress;


      if (percentGreen + percentOrange + percentRed === 0) {
        progressCol = (
          <React.Fragment>
            <Text style={styles.progressPercent}>0%</Text>
            <View style={styles.progressBackground}>
              <Text style={styles.progressNotStartedText}>not started</Text>
            </View>
          </React.Fragment>
        );
      } else {
        const beginStyleOrange =          percentGreen == 0
            ? { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
            : {};
        const beginStyleRed =          percentGreen + percentOrange == 0
            ? { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
            : {};
        const endStyleGreen =          percentGreen >= 1
            ? { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
            : {};
        const endStyleOrange =          percentGreen + percentOrange >= 1
            ? { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
            : {};
        const endStyleRed =          percentGreen + percentOrange + percentRed >= 1
            ? { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
            : {};

        const widthProp = widthAnim.interpolate({
          inputRange: [0, 1],
          outputRange: ['0%', '100%'],
        });

        const indicator =          percentTimeConsumed <= 0 || percentTimeConsumed == 1 ? null : (
            <Animated.View
              style={[
                styles.indicator,
                { left: `${percentTimeConsumed * 100}%`, opacity: fadeAnim },
              ]}
            />
          );
        progressCol = (
          <React.Fragment>
            <Text style={styles.progressPercent}>
              {(percentGreen * 100).toFixed(1)}
%
</Text>
            <View style={styles.progressBackground}>
              {(percentGreen === 1 && delta > 0) || indicator}
              <Animated.View
                style={[styles.progressBars, { width: widthProp }]}
              >
                <View
                  style={[
                    styles.progressGreen,
                    { flex: percentGreen * 320 },
                    endStyleGreen,
                  ]}
                />
                <View
                  style={[
                    styles.progressOrange,
                    { flex: percentOrange * 320 - 1 },
                    beginStyleOrange,
                    endStyleOrange,
                  ]}
                />
                <View
                  style={[
                    styles.progressRed,
                    { flex: percentRed * 320 - 2 },
                    beginStyleRed,
                    endStyleRed,
                  ]}
                />
                <View
                  style={[
                    styles.progressUnused,
                    {
                      flex:
                        320 - (percentGreen + percentOrange + percentRed) * 320,
                    },
                  ]}
                />
              </Animated.View>
            </View>
          </React.Fragment>
        );
      }
    } else {
      progressCol = null;
    }
    return (
      <View style={[styles.cell, styles.cellProgress]}>
        {progressCol}
      </View>
    );
  }
}

export default ProgressBar;
