/* eslint-disable camelcase */
// @flow

import * as React from 'react';
import {
  StyleSheet, View, ActivityIndicator, Text, Linking, TouchableWithoutFeedback,
} from 'react-native';
import { connect } from 'react-redux';
import ConstructionProgramTable from './ConstructionProgramTable';
import Header from './components/Header';
import InfoBoard from './components/InfoBoard';
import { fetchConstructionProgram } from '../store/actions';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  errorPage: {
    backgroundColor: '#F0F3F3',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  errorBox: {
    alignSelf: 'center',
    alignItems: 'center',
  },
  warnCode: {
    fontSize: 40,
    color: '#424856',
    paddingBottom: 40,
  },
  warnText: {
    fontSize: 20,
    color: '#565656',
    paddingBottom: 40,
  },
  backBox: {
    justifyContent: 'center',
    height: '3rem',
    backgroundColor: '#1f7bb6',
    width: '16rem',
    boxShadow: '0px 6px 0px #2273a5',
    borderRadius: 3,
  },
  backText: {
    color: 'white',
    fontFamily: 'Muli800',
    textAlign: 'center',
    fontSize: 15,
  },
  activityIndicator: {
    marginTop: 50,
  },
  table: {
    width: '100%',
    position: 'absolute',
    top: 268,
  },
});

type Props = {
  constructionProgram: ConstructionProgramView,
  fetchConstructionProgram: Function,
  data: Object,
  errorType: String,
  msg: String
};

type State = {};

export class ConstructionProgram extends React.Component<Props, State> {
  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.fetchConstructionProgram();
  }

  render() {
    const { data, msg, errorType } = this.props;
    if (data) {
      const { project: { project_name }, progressWhole, user } = data;
      return (
        <View style={styles.container}>
          <Header user={user} />
          <InfoBoard progress={progressWhole} projectName={project_name} />
          <View style={styles.table}>
            <ConstructionProgramTable data={data} />
          </View>
        </View>
      );
    }
    if (errorType) {
      return (
        <View style={styles.errorPage}>
          <View style={styles.errorBox}>
            <Text style={styles.warnCode}>{errorType}</Text>
            <Text style={styles.warnText}>{msg}</Text>
            <TouchableWithoutFeedback onPress={() => { Linking.openURL('/'); }}>
              <View style={styles.backBox}>
                <Text style={styles.backText}>
                  Back To Dashboard
                </Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
      );
    }
    return <ActivityIndicator style={styles.activityIndicator} size="large" color="#0000ff" />;
  }
}

const mapStateToProps = ({ schedule }) => {
  const { data, msg, errorType } = schedule;
  return { data, msg, errorType };
};

const mapDispatchToProps = {
  fetchConstructionProgram,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  ConstructionProgram,
);
