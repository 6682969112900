import React from 'react';
import {
  View, Text, StyleSheet,
} from 'react-native';
import SearchBar from './SearchBar';
import styles from './style';
import MainProgressBar from './MainProgressBar';
import WallChartBtn from './WallChartBtn';

const InfoBoard = ({ projectName, progress }: Object) => (
  <View style={styles.infoBoard}>
    <View style={styles.infoBoardContent}>
      <View style={styles.infoBoardContentLeft}>
        <Text style={styles.projectName}>{projectName}</Text>
        <View style={styles.projectProgressSection}>
          <MainProgressBar progress={progress} />
        </View>
      </View>
      <View style={styles.infoBoardContentRight}>
        {/* Hide this for now */}
        {/* <ImportBtn /> */}
        <WallChartBtn />
        <SearchBar />
      </View>
    </View>
  </View>
);

export default InfoBoard;
