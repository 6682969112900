// @flow

import _ from 'lodash';
import {
  FETCH_DASHBOARD_SUCCESS, TOGGLE_LEVEL_TASKS, TYPING, ERROR_TYPE,
} from './actions';

const INITIAL_STATE = {
  hiddenLevelIds: [],
  data: null,
  originalData: null,
  levelsMap: {},
  levelTaskRelations: {},
  query: '',
};

const normalizeString = string => string.toLowerCase();

export const ScheduleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LEVEL_TASKS:
      if (!action.show) {
        return {
          ...state,
          hiddenLevelIds: [
            ...state.hiddenLevelIds,
            action.rowId,
          ],
        };
      }
      if (action.show) {
        const hiddenLevelIds = state.hiddenLevelIds.filter(id => id !== action.rowId
          && state.levelsMap[id].parentId !== action.rowId);

        return {
          ...state,
          hiddenLevelIds,
        };
      }
      break;
    case FETCH_DASHBOARD_SUCCESS: {
      const levelsMap = {};
      if (action.data.msg) {
        return {
          ...state,
          data: action.data,
        };
      }
      action.data.rows.map((row) => {
        if (!row.isTask) {
          levelsMap[row.rowId] = row;
        }
      });
      const levelTaskRelations = _.groupBy(action.data.rows.filter(row => row.isTask), 'parentId');
      for (const key in levelTaskRelations) {
        if ({}.hasOwnProperty.call(levelTaskRelations, key)) {
          levelTaskRelations[key] = _.map(levelTaskRelations[key], 'rowId');
        }
      }
      return {
        ...state,
        data: action.data,
        originalData: action.data,
        levelsMap,
        levelTaskRelations,
      };
    }

    case ERROR_TYPE.AUTH_FAIL:
    case ERROR_TYPE.SEND_REQUEST_FAIL: {
      return {
        ...state,
        msg: action.msg,
        errorType: action.type,
      };
    }

    case TYPING: {
      const query = normalizeString(action.query);
      return {
        ...state,
        query,
      };
    }

    default:
      return state;
  }
};

export default ScheduleReducer;
