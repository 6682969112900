import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ScheduleReducer from './reducers';

const persistConfig = {
  key: 'root',
  whitelist: [],
  storage,
};

const initialState = {};
const rootReducer = combineReducers({
  schedule: ScheduleReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk];

const enhancer = composeWithDevTools(
  applyMiddleware(...middlewares),
  // offline(offlineConfig),
);

export default createStore(persistedReducer, initialState, enhancer);
