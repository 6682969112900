// @flow

import * as React from 'react';
import { View, Text, LayoutAnimation } from 'react-native';
import { connect } from 'react-redux';
import styles from './rowStyle';
import HealthCol from './components/HealthCol';
import OwnerCol from './components/OwnerCol';
import DateCol from './components/DateCol';
import DurationCol from './components/DurationCol';
import ProgressBar from './components/ProgressBar';

type Props = {
  task: TaskRowView,
  company: Object
};

type State = {};

export class TaskRow extends React.PureComponent<Props, State> {
  componentDidMount() {}

  componentDidUpdate() {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }

  render() {
    const { task, company } = this.props;
    const {
 timestamp, startDate, endDate, lastUpdate 
} = task;
    return (
      <View style={styles.container}>
        <View style={[styles.cell, styles.cellLine]}>
          <Text style={styles.cellLineText}>{task.line}</Text>
        </View>
        <View style={[styles.cell, styles.cellName]}>
          <Text
            ellipsizeMode
            numberOfLines={1}
            style={[styles.cellNameTask, { marginLeft: task.depth * 20 }]}
          >
            {task.name}
          </Text>
        </View>
        <HealthCol
          health={task.health}
          timestamp={timestamp}
          endDate={endDate}
          done={task.progress.percentGreen === 1}
        />
        <ProgressBar progress={task.progress} delta={task.health.dayDelta} />
        <OwnerCol owner={company} />
        <DateCol lastUpdate={lastUpdate} />
        <DurationCol startDate={startDate} endDate={endDate} />
      </View>
    );
  }
}

export default TaskRow;
