import React, { useState } from 'react';
import { View, Text } from 'react-native';
import moment from 'moment-timezone';
import styles from '../rowStyle';

const localTimeZone = moment.tz.guess();
moment.tz.setDefault(localTimeZone);

const processDurationString = (startDate, endDate) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const startMonth = startMoment.month();
  const endMonth = endMoment.month();
  const startString = startMoment.format('MMM Do');
  const endString = endMoment.format('MMM Do');
  const duration = startMonth !== endMonth
    ? `${startString} - ${endString}`
    : `${startString} - ${endString.split(' ')[1]}`;
  return duration;
};

const calculateDuration = (startDate, endDate) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  console.log(endMoment)
  const daysDuration = Math.ceil(endMoment.diff(startMoment, 'days', true));
  const hoursDuration = Math.ceil(endMoment.diff(startMoment, 'hours', true));
  const duration = daysDuration > 0
    ? daysDuration > 1
      ? daysDuration + ' days'
      : daysDuration + ' day'
    : hoursDuration > 1
      ? hoursDuration + ' hours'
      : hoursDuration + ' hour';
  return duration;
};

const DurationCol = ({ startDate, endDate }) => {
  const [hover, setHover] = useState(false);
  const durationString = processDurationString(startDate, endDate);
  const duration = calculateDuration(startDate, endDate);

  return (
    <View
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={[
        styles.cell,
        styles.cellDuration,
        hover ? styles.cellDurationGrey : {},
      ]}
    >
      <View style={styles.cellDurationWrap}>
        <Text
          style={[
            styles.cellDurationText,
            hover ? styles.cellDurationTextGrey : {},
          ]}
        >
          {hover ? duration : durationString}
        </Text>
      </View>
    </View>
  );
};

export default DurationCol;
