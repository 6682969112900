export default function getExampleConstructionProgram(): ConstructionProgramView {
  const project: MatrakProject = {
    name: '500 Swanston St',
    id: 0,
  };

  const companiesByID: Map<number, MatrakCompany> = new Map();
  companiesByID.set(0, {
    id: 0,
    name: 'Wall & Co.',
  });
  companiesByID.set(1, {
    id: 1,
    name: 'Fires Inc.',
  });
  companiesByID.set(2, {
    id: 2,
    name: 'Window Co.',
  });
  companiesByID.set(3, {
    id: 3,
    name: 'Roofline',
  });
  companiesByID.set(4, {
    id: 4,
    name: 'Vanity Plus',
  });
  companiesByID.set(5, {
    id: 5,
    name: 'Blue Steel',
  });

  const rows: Array<RowView> = [
    {
      rowId: 99999,
      line: null,
      name: 'East Tower',
      health: null,
      progress: null,
      owner: null,
      duration: null,
      isTask: false,
      parentId: null,
      lastUpdate: null,
      depth: 0,
    },
    {
      rowId: 10000,
      line: null,
      name: 'Level 1',
      health: null,
      progress: null,
      owner: null,
      duration: null,
      isTask: false,
      parentId: 99999,
      lastUpdate: null,
      depth: 1,
    },
    {
      rowId: 12334,
      line: 173,
      name: 'Task aaaaa',
      health: null,
      progress: {
        percentTimeConsumed: 0,
        percentGreen: 0,
        percentOrange: 0,
        percentRed: 0,
      },
      owner: 4,
      duration: '2 days',
      isTask: true,
      parentId: 10000,
      lastUpdate: new Date(2019, 5, 10),
      depth: 2,
    }, {
      rowId: 12355,
      line: 160,
      name: 'Task5',
      health: {
        summary: 'red',
        dayDelta: 2,
      },
      progress: {
        percentTimeConsumed: 1,
        percentGreen: 0.73,
        percentOrange: 0,
        percentRed: 0.27,
      },
      owner: 3,
      duration: '3 days',
      isTask: true,
      parentId: 10000,
      lastUpdate: new Date(2019, 5, 10),
      depth: 2,
    }, {
      rowId: 12522,
      line: 159,
      name: 'Task4',
      health: {
        summary: 'orange',
        dayDelta: 0,
      },
      progress: {
        percentTimeConsumed: 0.8,
        percentGreen: 0.52,
        percentOrange: 0.4,
        percentRed: 0,
      },
      duration: '4 days',
      owner: 3,
      isTask: true,
      parentId: 10000,
      lastUpdate: new Date(2019, 5, 10),
      depth: 2,
    },
    {
      rowId: 10001,
      line: null,
      name: 'Level 2 - Contempo Rise',
      health: null,
      progress: null,
      owner: null,
      duration: null,
      isTask: false,
      parentId: 99999,
      lastUpdate: null,
      depth: 1,
    },
    {
      rowId: 25555,
      line: 158,
      name: 'Task3',
      health: {
        summary: 'red',
        dayDelta: 2,
      },
      progress: {
        percentTimeConsumed: 1,
        percentGreen: 0.68,
        percentOrange: 0,
        percentRed: 0.32,
      },
      duration: '3 days',
      owner: 2,
      isTask: true,
      parentId: 10001,
      lastUpdate: new Date(2019, 5, 10),
      depth: 2,
    }, {
      rowId: 14444,
      line: 156,
      name: 'Task1',
      health: {
        summary: 'green',
        dayDelta: 0,
      },
      progress: {
        percentTimeConsumed: 1,
        percentGreen: 1,
        percentOrange: 0,
        percentRed: 0,
      },
      duration: 'Apr 12th - 14th',
      owner: 0,
      isTask: true,
      parentId: 10001,
      lastUpdate: new Date(2019, 5, 13),
      depth: 2,
    },
    {
      rowId: 99998,
      line: null,
      name: 'West Tower',
      health: null,
      progress: null,
      owner: null,
      duration: null,
      isTask: false,
      parentId: null,
      lastUpdate: null,
      depth: 0,
    },
    {
      rowId: 10003,
      line: null,
      name: 'Level 1 West',
      health: null,
      progress: null,
      owner: null,
      duration: null,
      isTask: false,
      parentId: 99998,
      lastUpdate: null,
      depth: 1,
    },
    {
      rowId: 55555,
      line: 166,
      name: 'Task33',
      health: null,
      progress: {
        percentTimeConsumed: 0,
        percentGreen: 0,
        percentOrange: 0,
        percentRed: 0,
      },
      duration: '4 days',
      owner: 3,
      isTask: true,
      parentId: 10003,
      lastUpdate: new Date(2019, 5, 12),
      depth: 2,
    },
    {
      rowId: 66666,
      line: 266,
      name: 'Task 2',
      health: {
        summary: 'red',
        dayDelta: 2,
      },
      progress: {
        percentTimeConsumed: 0.48,
        percentGreen: 0.62,
        percentOrange: 0.1,
        percentRed: 0.05,
      },
      owner: 0,
      isTask: true,
      parentId: 10003,
      lastUpdate: new Date(2019, 5, 14),
      depth: 2
    }
  ];

  return {
    project,
    rows,
    companiesByID,
  };
}