import {
  StyleSheet,
} from 'react-native';

const styles = StyleSheet.create({
  header: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 120,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: '#424242',
    shadowOpacity: 0.2,
    shadowRadius: 17,
    backgroundColor: 'white',
    flex: 100,
  },
  headerLeft: {
    flexDirection: 'row',
    flex: 37,
  },
  headerMiddle: {
    justifyContent: 'center',
    flex: 48,
  },
  headerMiddleText: {
    fontFamily: 'Muli900',
    fontSize: 34,
    color: '#424242',
  },
  headerRight: {
    flexDirection: 'row',
    flex: 15,
  },
  profileImage: {
    justifyContent: 'center',
    marginRight: 10,
  },
  profileName: {
    justifyContent: 'center',
  },
  image: {
    height: 48, width: 48, borderRadius: 24, backgroundColor: '#D8D8D8', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
  },
  imageText: {
    fontFamily: 'Muli900', fontSize: 24, lineHeight: 30, color: '#A8A8A8',
  },
  logoWrap: {
    justifyContent: 'center',
  },
  logo: {
    width: 168,
    height: 36,
    marginLeft: 30,
  },
  nameText1: { fontFamily: 'Muli900', color: '#424242', fontSize: 14 },
  nameText2: { fontFamily: 'Muli', color: '#424242', fontSize: 14 },
  indicator: {
    backgroundColor: '#FF6358',
    width: 2,
    height: 20,
    position: 'absolute',
    left: 0,
    top: -8,
    zIndex: 1,
  },
  infoBoard: {
    height: 100, justifyContent: 'center', position: 'fixed', top: 120, width: '100%', zIndex: 500,
  },
  infoBoardContent: {
    flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
  },
  infoBoardContentLeft: { flexDirection: 'row' },
  infoBoardContentRight: { flexDirection: 'row', alignItems: 'flex-end' },
  projectName: {
    fontFamily: 'Muli900', fontSize: 26, color: '#424242', marginLeft: 25,
  },
  projectProgressSection: { marginLeft: 25 },
  projectProgressText: {
    fontSize: 12, fontFamily: 'Muli900', color: '#A8A8A8', marginBottom: 8,
  },
  projectProgressBar: { flexDirection: 'row' },
  importSection: { alignItems: 'center', marginRight: 32 },
  importIconWrap: {
    alignItems: 'center', justifyContent: 'center', height: 48, width: 48, borderRadius: 5, backgroundColor: 'white',
  },
  chartIconWrap: {
    height: 48, width: 48, borderRadius: 5, backgroundColor: 'white', justifyContent: 'flex-end', alignItems: 'center',
  },
  importShadow: {
    shadowColor: '#6F6F6F', shadowOpacity: 0.4, shadowOffset: { width: 0, height: 0 }, shadowRadius: 7,
  },
  importIcon: {
    width: 26, height: 32,
  },
  wallchartIcon: {
    width: 42, height: 26, marginBottom: 10,
  },
  importText: { fontFamily: 'Muli', color: '#6F6F6F', fontSize: 11 },
  wallchartSection: { alignItems: 'center', marginRight: 32 },
  wallchartText: { fontFamily: 'Muli', color: '#6F6F6F', fontSize: 11 },
  searchSection: {
    alignItems: 'center', flexDirection: 'row', height: 48, width: 367, borderColor: '#C4C4C4', borderRadius: 10, borderWidth: 1, alignSelf: 'center', marginRight: 20,
  },
  searchIcon: { padding: 8 },
  searchPlaceholder: {
    fontFamily: 'Muli600', fontSize: 16, width: 350, height: 36, outlineColor: 'white',
  },
});

export default styles;
