// @flow
import * as React from 'react';
import {
  View, Animated, Text, TouchableWithoutFeedback,
} from 'react-native';
import styles from './rowStyle';
import HealthCol from './components/HealthCol';
import ProgressBar from './components/ProgressBar';

type Props = {
  level: LevelRowView,
};

type State = {
  showChildren: Bool
};

class LevelRow extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      levelRotateAnim: new Animated.Value(0),
    };
  }

  componentDidUpdate() {
    const { levelRotateAnim } = this.state;
    const { showIndicator } = this.props;

    Animated.timing(
      levelRotateAnim,
      {
        toValue: showIndicator ? 1 : 0,
        duration: 300,
        delay: 0,
        useNativeDriver: true,
      },
    ).start();
  }

  render() {
    const { level, onClick } = this.props;
    const { levelRotateAnim, show } = this.state;

    const rotateProp = levelRotateAnim.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '90deg'],
    });

    if (show) {
      return (
        <View style={styles.container}>
          <View style={[styles.cell, styles.cellLine]}>
            <Text style={styles.cellLineText}>{level.line}</Text>
          </View>
          <TouchableWithoutFeedback
            onPress={() => onClick()}
            hitSlop={{
              top: 5,
              left: 60,
              right: 50,
              bottom: 5,
            }}
          >
            <View style={[styles.cell, styles.cellName]}>
              <View
                style={[
                  styles.cellNameTriangleWrap,
                  { marginLeft: level.depth * 20 },
                ]}
              >
                <Animated.View
                  style={[
                    styles.cellNameTriangleRight,
                    { transform: [{ rotate: rotateProp }] },
                  ]}
                >
                </Animated.View>
              </View>
              <Text
                ellipsizeMode
                numberOfLines={1}
                style={styles.cellNameLevel}
              >
                {level.name}
              </Text>
            </View>
          </TouchableWithoutFeedback>
          <HealthCol health={level.health} />
          <ProgressBar progress={level.progress} />
          <View style={[styles.cell, styles.cellOwner]} />
          <View style={[styles.cell, styles.cellUpdate]} />
          <View style={[styles.cell, styles.cellDuration]} />
        </View>
      );
    }
    return null;
  }
}

export default LevelRow;
