/* eslint-disable no-nested-ternary */
import React from 'react';
import { View, Text, Image } from 'react-native';
import styles from './style';

const Header = ({ user }: Object) => (
  <View style={styles.header}>
    <View style={styles.headerLeft}>
      <View style={styles.logoWrap}>
        <Image
          style={styles.logo}
          source={require('../../../assets/logoLarge.png')}
        />
      </View>
    </View>
    <View style={styles.headerMiddle}>
      <Text style={styles.headerMiddleText}>Construction Programme</Text>
    </View>
    <View style={styles.headerRight}>
      {/* <View style={styles.profileImage}>
        <View style={styles.image}>
          <Text style={styles.imageText}>
            {user
              ? user.first_name.length > 0 && user.last_name.length > 0
                ? `${user.first_name[0]}${user.last_name[0]}`
                : 'NI'
              : 'NI'}
          </Text>
        </View>
      </View>
      <View style={styles.profileName}>
        <Text style={styles.nameText1}>{user ? user.first_name : 'No'}</Text>
        <Text style={styles.nameText2}>{user ? user.last_name : 'Info'}</Text>
      </View> */}
    </View>
  </View>
);

export default Header;
