import React from 'react';
import {
  View, Text,
} from 'react-native';
import styles from '../rowStyle';

class DateCol extends React.PureComponent {
  render() {
    const { lastUpdate } = this.props;
    if (lastUpdate) {
      return (
        <View style={[styles.cell, styles.cellUpdate]}>
          <Text style={styles.cellUpdateText}>{lastUpdate}</Text>
        </View>
      );
    }
    return (
      <View style={[styles.cell, styles.cellUpdate]}>
        <Text style={styles.cellNull}>- -</Text>
      </View>
    );
  }
}

export default DateCol;
