/* eslint-disable react/jsx-indent */
import React from 'react';
import { View, Text } from 'react-native';
import styles from './style';

const MainProgressBar = ({ progress }: Object) => {
  if (progress) {
    const progressGreen = { width: 300 * progress.percentGreen, backgroundColor: '#4AD238', height: 4 };
    const progressGrey = { width: 300 - 300 * progress.percentGreen, backgroundColor: '#E0E0E0', height: 4 };
    return (
            <React.Fragment>
                <Text style={styles.projectProgressText}>
                    {(progress.percentGreen * 100).toFixed(1)}
                    %
                </Text>
                <View style={styles.projectProgressBar}>
                    <View style={progressGreen} />
                    <View style={progressGrey} />
                    <View style={[styles.indicator, { left: 300 * progress.percentTimeConsumed }]} />
                </View>
            </React.Fragment>
    );
  }
  return null;
};

export default MainProgressBar;
