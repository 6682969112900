import React from 'react';
import { View, Text } from 'react-native';
import styles from '../rowStyle';

const OwnerCol = ({ owner }: MatrakCompany) => (
  <View style={[styles.cell, styles.cellOwner]}>
    {owner ? (
      <React.Fragment>
        <View style={styles.cellOwnerDropDown}>
          <View style={styles.cellOwnerTriangleWrap}>
            <View style={styles.cellOwnerTriangle} />
          </View>
        </View>
        <Text style={styles.cellOwnerName}>{owner.name}</Text>
      </React.Fragment>
    )
      : null}

  </View>
);

export default OwnerCol;
