// @flow

import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import ConstructionProgram from './construction-program/ConstructionProgram';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

type Props = {};

type State = {};


class Scheduler extends React.Component<Props, State> {
  componentDidMount() {

  }

  render() {
    return (
      <View style={styles.container}>
        <ConstructionProgram />
      </View>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  // example: () => dispatch(exampleAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
