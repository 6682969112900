// @flow
import getExampleConstructionProgram from '../data/dummyDatabase';
import AuthManager from '../AuthManager';

export const TOGGLE_LEVEL_TASKS = 'TOGGLE_LEVEL_TASKS';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const ERROR_TYPE = {
  AUTH_FAIL: 'AUTHENTICATION FAIL',
  SEND_REQUEST_FAIL: 'REQUEST FAILED TO SEND',
};
export const TYPING = 'TYPING';

const switchBaseUrl = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return 'http://localhost:8080';
    case 'construction-programme-dev.matrak.com.au':
    case 'construction-programme-dev.matrak.com':
      return 'https://dapi.matrak.com.au';
    case 'construction-programme.matrak.com.au':
    case 'construction-programme.matrak.com':
      return 'https://api.matrak.com.au';
    default:
      return 'https://api.matrak.com.au';
  }
};
export const URL = `${switchBaseUrl()}/api/v1/scheduling`;
export const CHART_URL = `${switchBaseUrl()}/api/v1/scheduling/wallchart`;

type ToggleLevelTasksAction = { show: Bool, taskIds: Array<Number> }
type TypingAction = { query: String }


export type ScheduleProps = {
  toggleLevelTaskAction: (show: Bool, taskIds: Array<Number>) => Array <Number>;
  fetchConstructionProgram: () => Object
}

export const toggleLevelTaskAction = (show, rowId): ToggleLevelTasksAction => ({
  type: TOGGLE_LEVEL_TASKS,
  show,
  rowId,
});

export const success = data => ({
  type: FETCH_DASHBOARD_SUCCESS,
  data,
});
export const errorAuth = msg => ({
  type: ERROR_TYPE.AUTH_FAIL,
  msg,
});
export const errorSendRequest = msg => ({
  type: ERROR_TYPE.SEND_REQUEST_FAIL,
  msg,
});

export const fetchConstructionProgram = (): FetchDataAction => {
  const exampleData = getExampleConstructionProgram();
  return (dispatch) => {
    const authObject = AuthManager.getAuthFromWindow();
    if (authObject) {
      const {userid, auth, cpid} = authObject;
      return fetch(URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer legacy.${userid}.${auth}.${cpid}`,
        },
        body: JSON.stringify({
          id: cpid,
        }),
      }).then(response => response.json()).then((responseJson) => {
        if (responseJson.code === 401) {
          console.warn(responseJson.message); // auth fail error
          dispatch(errorAuth(responseJson.message));
        } else {
          const data = {
            project: responseJson.project,
            rows: responseJson.rows,
            companiesByID: exampleData.companiesByID,
            progressWhole: responseJson.progressWhole,
            user: responseJson.user,
          };
          console.log(data);
          dispatch(success(data));
        }
      }).catch((error) => {
        console.warn(error); // send request fail error
        dispatch(errorSendRequest('Something wrong with the request.'));
      });
    }
    console.warn('no auth info in localstorage'); // local storage auth non exist error
    dispatch(errorAuth('The authentication failed.'));
  };
};

export const typing = (query): TypingAction => ({
  type: TYPING,
  query,
});
