import React from 'react';
import { View, TextInput } from 'react-native';
import {
  Ionicons,
} from '@expo/vector-icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import styles from './style';
import { typing } from '../../store/actions';

export const SearchBar = (props) => {
  const search = (text) => {
    // eslint-disable-next-line react/prop-types
    props.typing(text);
  };

  const debounceSearch = _.debounce(search, 500);
  return (
    <View style={styles.searchSection}>
      <Ionicons
        name="md-search"
        size={20}
        color="#E0E0E0"
        style={styles.searchIcon}
      />
      <TextInput
        placeholder="Quick search"
        style={styles.searchPlaceholder}
        placeholderTextColor="#E0E0E0"
        onChangeText={debounceSearch}
      />
    </View>
  );
};

const mapDispatchToProps = {
  typing,
};

export default connect(null, mapDispatchToProps)(SearchBar);
