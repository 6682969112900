// @flow
import * as React from 'react';
import { View } from 'react-native';
import LevelRow from './LevelRow';
import TaskRow from './TaskRow';

class LevelWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChildren: false,
      lastQuery: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { item, query } = nextProps;
    if (query !== prevState.lastQuery) {
      const { name } = item;
      const showChildren = query === '' ? false : name.toLowerCase().includes(query) ? false : true;
      return {
        showChildren,
        lastQuery: query,
      };
    }
    return null;
  }

  handleOnClick = () => {
    const { showChildren } = this.state;
    this.setState({
      showChildren: !showChildren,
    });
  };

  renderItem = (item) => {
    const { query } = this.props;
    const { showChildren } = this.state;
    const { isTask, children } = item;
    if (!isTask) {
      return (
        <View>
          <LevelRow
            level={item}
            onClick={this.handleOnClick}
            showIndicator={showChildren}
          />
          {
            showChildren && children.map(c => (
              <LevelWrapper
                item={c}
                key={c.rowId.toString() + c.name.toString()}
                query={query}
              />
            ))
          }
        </View>
      );
    }
    return <TaskRow task={item} />;
  };

  render() {
    const { item } = this.props;
    return this.renderItem(item);
  }
}

export default LevelWrapper;
